import React,{useEffect, useState} from "react";
import utpannaekadashi from '../images/EXTERNAL-UTPANNAEKADASHI.jpg';
import ekadashi from '../images/ekadashi-2023.jpg';
import govardhanpuja from '../images/Govardhan-Puja.jpg';
import understandinggrowth from '../images/Understanding-Growth-and-Evolution-through-Reincarnation-iskcon-banner-1.jpg';
import shrivamana from '../images/Vamana-Dvadasi-2.jpg';
import nurturing from '../images/Nurturing-Healthy-Body-Image-Strategies-for-Recovery-from-Eating-Disorders-iskcon.jpg';
import "../styles/blog.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";

export default function Blog() {
  const[blogData,setBlogData]=useState([]);
  const navigate=useNavigate();

  useEffect(() => {
    const firestore = firebase.firestore();
    const blogDataRef = firestore.collection("BlogData");
    const unsubscribe = blogDataRef.onSnapshot((snapshot) => {
      const newBlogData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(newBlogData);
      setBlogData(newBlogData);
    });
    return () => unsubscribe();
  }, []);

  const chunkArray = (arr, size) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArray.push(arr.slice(i, i + size));
    }
    return chunkedArray;
  };

  const handleBlogClick = (entry) => {
    navigate(`/blog-next/${entry.title}`, { state: { entryData: entry } });
  };

  return (
    <div className="blogdiv">
      <div className="blogheading">
        <h1 style={{fontWeight:"600"}}>Blog</h1>
        <p>
          Krishna once said that good deeds made, gifted, frugal, and done
          without faith are shown as no or “assat.” Therefore, neither here nor
          below is useful. Let us take a step closer to the devotion of Kanha
          and do our bit to help others in need.
        </p>
      </div>

      <div className="blogrow">
          <div className="blogcolumn">
          <a href="/utpanna-ekadashi" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src={utpannaekadashi} alt="utpannaekadashi" />
          <p>Utpanna Ekadashi 2023: Iskcon Puri</p>
          </a>
        </div>
       
        
        <div className="blogcolumn">
          <a href="/ekadashi-2023" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src={ekadashi} alt="ekadashi-2023" />
          <p>Ekadashi 2023 – Calendar, Vrat Timings, Puja Vidhi & Significance</p>
          </a>
        </div>

        <div className="blogcolumn">
          <a href="/govardhan-puja" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src={govardhanpuja} alt="govardhanpuja" />
          <p>Govardhan Puja 2023: Date, Time, Story & Muhurat</p>
          </a>
        </div>
      </div>

      <div className="blogrow">
        <div className="blogcolumn">
          <a href="/understanding-growth" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src={understandinggrowth} alt="january" />
          <p>Understanding Growth and Evolution through Reincarnation</p>
          </a>
        </div>

        <div className="blogcolumn">
          <a href="/shri-vamana-dvadashi" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src={shrivamana} alt="shrivamana" />
          <p>Shri Vamana Dvadashi – Appearance Day of Lord Vamanadeva</p>
          </a>
        </div>

        <div className="blogcolumn">
          <a href="/nurturing-healthy" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src={nurturing} alt="nurturing" />
          <p>Nurturing Healthy Body Image: Strategies for Recovery from Eating Disorders</p>
          </a>
        </div>
      </div>

      
        {chunkArray(blogData, 3).map((row, rowIndex) => (
        <div className="blogrow" key={rowIndex}>
          {row.map((entry) => (
            <div className="blogcolumn" key={entry.id}>
             <div onClick={() => handleBlogClick(entry)} style={{ textDecoration: "none", color: "black", cursor: "pointer" }}>
                 <img className="blogimage" src={entry.imageUrl} alt={entry.title} />
                 <p>{entry.title}</p>
             </div>
            </div>
          ))}
        </div>
      ))}

    </div>
  );
}
