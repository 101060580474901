import React, { useState, useEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";

import LoginScreen from "./screeen/LoginScreen";
import NotFound from "./screeen/NotFound";
import ImageUpload from "./screeen/ImageUpload.js";

// Function to check if the user is authenticated
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import HomeScreen from "./screeen/HomeScreen.js";
import Register from "./screeen/Register.js";

//neew code
import "./App.css";
import CheckoutPage from "./components/CheckoutPage";
import loadingImage from "./images/harekrishna.gif";
import AboutSrilaJi from "./screeen/aboutUs/AboutSrilaJi.jsx";
import History from "./screeen/aboutUs/History.jsx";
import Donate from "./screeen/aboutUs/Donate.jsx";
import KrishnaMovement from "./screeen/aboutUs/KrishnaMovement.jsx";
import PhotoScreen from "./screeen/PhotoScreen.js";
import GopalKrishna from "./screeen/aboutUs/GopalKrishna.jsx";
import Philosphy from "./screeen/aboutUs/Philosophy.jsx";
import FAQ from "./screeen/aboutUs/FAQ.jsx";
import ContactUs from "../src/components/ContactUs.jsx";
import ConnectUsOnline from "../src/components/ConnectUsOnline.jsx";
import GetEmailUpdate from "./components/GetEmailUpdate.jsx";
import HowToReach from "../src/components/HowToReach.jsx";
import DownloadApp from "./components/DownloadApp.jsx";
import DownloadWallpaperCalender from "./components/DownloadWallpaperCalender.jsx";
import Blog from "./components/Blog.jsx";
import TempleConstruction from "./screeen/Donation/TempleConstruction.jsx";
import GitaDaamSeva from "./screeen/Donation/GitaDaamSeva.jsx";
import SadhuBhojanSeva from "./screeen/Donation/SadhuBhojanSeva.jsx";
import FoodForLife from "./screeen/Donation/FoodForLife.jsx";
import DailyDeitySeva from "./screeen/Donation/DailyDeitySeva.jsx";
import GauSeva from "./screeen/Donation/GauSeva.jsx";
import FeastSponsorshipSeva from "./screeen/Donation/FeastSponsorshipSeva.jsx";
import NityaSeva from "./screeen/Donation/NityaSeva.jsx";
import Privacypolicy from "./components/privacy-policy.jsx";
import WhatsInside from "./components/WhatsInside.jsx";
import Activities from "./components/Activities.jsx";
import Events from "./components/Events.jsx";
import VaishnavaCalender from "./components/VaishnavaCalender.jsx";
import Timing from "./components/Timing.jsx";
import FCRAReport from "./components/FCRAReport.jsx";
import NityanandTrayodasi from "./screeen/festival/NityanandTrayodasi.jsx";
import Ramnavmi from "./screeen/festival/Ramnavmi.jsx";
import ChandanYatra from "./screeen/festival/ChandanYatra.jsx";
import NarsimhaChaturdashi from "./screeen/festival/NarsimhaChaturdashi.jsx";
import JagannathRathYatra from "./screeen/festival/JagannathRathYatra.jsx";
import RadhaKrishnaJhulanYatra from "./screeen/festival/RadhaKrishnaJhulanYatra.jsx";
import BalaramJayanti from "./screeen/festival/BalaramJayanti.jsx";
import Janmastami from "./screeen/festival/Janmastami.jsx";
import UtpannaEkadashi from "./components/UtpannaEkadashi.jsx";
import KartikAndDipwali from "./screeen/festival/KartikAndDipwali.jsx";
import GovardhanPuja from "./screeen/festival/GovardhanPuja.jsx";
import Gopastami from "./screeen/festival/Gopastami.jsx";
import GitaJayanti from "./screeen/festival/GitaJayanti.jsx";
import HarimanSankirtan from "./screeen/festival/HarimanSankirtan.jsx";
import TermsAndCondition from "./components/TermsAndCondition.jsx";
import Janmashtami from "./screeen/festival/Janmashtami.jsx";
import Ekadashi from "./screeen/festival/Ekadashi.jsx";
import WorldHolyName from "./screeen/festival/WorldHolyName.jsx";
import ForgotPassword from "./screeen/ForgotPassword.js";
import Ekadashi2023 from "./components/Ekadashi2023.jsx";
import GovardhanPooja from "./components/GovardhanPooja.jsx";
import UnderstandingGrowth from "./components/UnderstandingGrowth.jsx";
import VamanaDvadashi from "./components/VamanaDvadashi.jsx";
import NurturingHealth from "./components/NurturingHealth.jsx";
import Content from "./components/Content/Content.jsx";
import Difference from "./components/Difference.jsx";
import TemplePlan from "./components/TemplePlan.jsx";
import Count from "./components/Count.jsx";
import Footer1 from "./components/Footer1.jsx";
import Contribution from "./components/Contribution.jsx";
import BlogNext from "./components/BlogNext.jsx";
import NewHeader from "./components/NewHeader.jsx";
import Gallery from "./components/Gallery.jsx";
import DarshanUpdates from './components/DarshanUpdates.jsx';
import FloatingActionButtons from './components/FloatButton.jsx';
import VideoScreeen from "./screeen/VIdeoScreen.js";
import CheckOutNext from "./components/CheckOutNext.jsx";
import RathYatra from "./screeen/festival/RathYatra.jsx";
const isAuthenticated = () => {
  const currentUser = firebase.auth().currentUser;

  return currentUser !== null;
};

const ProtectedRoute = ({ path, element }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, [navigate]);

  return isAuthenticated() ? element : null;
};

const Layout = () => {
  return (
    <>
      <NewHeader/>
      <Outlet />
      <FloatingActionButtons />
      <Footer1/>
    </>
  );
};

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomeScreen />,
      },
      {
        path: "/upload",
        element: <ProtectedRoute element={<ImageUpload />} />, // Protect this route
      },
      {
        path: "/checkout",
        element: <CheckoutPage />,
      },
      {
        path: "/login",
        element: <LoginScreen />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/signup",
        element: <Register />,
      },
      {
        path: "/about-srila-prabhupada",
        element: <AboutSrilaJi />,
      },
      {
        path: "/about-us",
        element: <History />,
      },
      {
        path: "/history-Of-ISKCON",
        element: <History />,
      },
      {
        path: "/history-of-chaitanya-mahaprabhu",
        element: <Donate />,
      },
      {
        path: "/hare-krishna-movement",
        element: <KrishnaMovement />,
      },
      {
        path: "/get-connect-locally",
        element: <ContactUs />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/connect-us-online",
        element: <ConnectUsOnline />,
      },
      {
        path: "/get-email-update",
        element: <GetEmailUpdate />,
      },
      {
        path: "/how-to-reach",
        element: <HowToReach />,
      },
      {
        path: "/video-gallery",
        element: <VideoScreeen/>
      },
      {
        path: "/photo-gallery",
        element: <PhotoScreen />,
      },
      {
        path:"/darshan-updates",
        element:<DarshanUpdates />
      },
      {
        path: "/history-of-jagganath-temple",
        element: <GopalKrishna />,
      },
      {
        path: "/our-philosophy",
        element: <Philosphy />,
      },
      {
        path: "/ask-questions-ISKCON",
        element: <FAQ />,
      },
      {
        path: "/download-app",
        element: <DownloadApp />,
      },
      {
        path: "/download-wallpaper-calender",
        element: <DownloadWallpaperCalender />,
      },
      {
        path: "/blog",
        element: <Blog/>,
      },
      {
        path:"/utpanna-ekadashi",
        element:<UtpannaEkadashi/>
      },
      {
        path:"/ekadashi-2023",
        element:<Ekadashi2023/>
      },
      {
        path:"/govardhan-puja",
        element:<GovardhanPooja/>
      },
      {
        path:"/understanding-growth",
        element:<UnderstandingGrowth/>
      },
      {
        path:"/shri-vamana-dvadashi",
        element:<VamanaDvadashi/>
      },
      {
        path:"/nurturing-healthy",
        element:<NurturingHealth/>
      },
      {
        path: "/whats-inside",
        element: <WhatsInside />,
      },
      {
        path: "/activities",
        element: <Activities />,
      },
      {
        path: "/events",
        element: <Events />,
      },
      {
        path: "/timing",
        element: <Timing />,
      },
      {
        path: "/vaishnava-calender",
        element: <VaishnavaCalender />,
      },
      {
        path: "/fcra-report",
        element: <FCRAReport />,
      },
      {
        path: "/nityanand-trayodasi",
        element: <NityanandTrayodasi />,
      },
      {
        path: "/ram-navami",
        element: <Ramnavmi />,
      },
      {
        path: "/chandan-yatra",
        element: <ChandanYatra />,
      },
      {
        path: "/narsimha-chaturdashi",
        element: <NarsimhaChaturdashi />,
      },
      {
        path: "/jagannath-rath-yatra",
        element: <JagannathRathYatra />,
      },
      {
        path: "/radha-krishna-jhulan-yatra",
        element: <RadhaKrishnaJhulanYatra />,
      },
      {
        path: "/balaram-jayanti",
        element: <BalaramJayanti />,
      },
      {
        path: "/janmastami",
        element: <Janmastami />,
      },
      {
        path: "/fcra-report",
        element: <FCRAReport />,
      },
      {
        path: "/donate-temple-construction-seva",
        element: <TempleConstruction />,
      },
      {
        path: "/donate-shastra",
        element: <GitaDaamSeva />,
      },
      {
        path: "/donate-sadhu-bhojan-seva",
        element: <SadhuBhojanSeva />,
      },
      {
        path: "/donate-food-for-life",
        element: <FoodForLife />,
      },
      {
        path: "/donate-deity-seva",
        element: <DailyDeitySeva />,
      },
      {
        path: "/donate-gau-seva",
        element: <GauSeva />,
      },
      {
        path: "/sponsor-feast",
        element: <FeastSponsorshipSeva />,
      },
      {
        path: "/nitya-seva",
        element: <NityaSeva />,
      },
      {
        path: "/privacy-policy",
        element: <Privacypolicy />,
      },
      {
        path: "/terms-and-condition",
        element: <TermsAndCondition />,
      },
      {
        path: "/kartik-and-deepawali",
        element: <KartikAndDipwali />,
      },
      {
        path: "/govardhan-puja",
        element: <GovardhanPuja />,
      },
      {
        path: "/2023/11/08/govardhana-puja/",
        element: <GovardhanPuja />,
      },
      {
        path: "/gopastami",
        element: <Gopastami />,
      },
      {
        path: "/gita-jayanti",
        element: <GitaJayanti />,
      },
      {
        path: "/harinam-sankirtan",
        element: <HarimanSankirtan />,
      },
      {
        path: "/iskcon-janmashtami-celebration",
        element: <Janmashtami />,
      },
      {
        path: "/ekadashi",
        element: <Ekadashi />,
      },
      {
        path: "/world-holy-name-festival",
        element: <WorldHolyName />,
      },
      {
        path:"/content",
        element:<Content/>
      },
      {
        path:"/difference",
        element:<Difference/>
      },
      {
        path:"/templeplan",
        element:<TemplePlan/>
      },
      {
        path:"/count",
        element:<Count/>
      },
      {
        path:"/contribute",
        element:<Contribution/>
      },
      {
        path:"/donation",
        element:<Contribution/>
      },
      {
        path:"/blog-next/:title",
        element:<BlogNext/>
      },
      {
        path:"/image-gallery",
        element:<Gallery/>
      },
      {
        path:"/checkoutnext",
        element:<CheckOutNext/>
      },
      {
        path:"/rath-yatra",
        element:<RathYatra/>
      }
    ],
  },
  {
    path: "/*",
    element: <NotFound />,
  },
];

const router = createBrowserRouter(routes);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <section
          style={{
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={loadingImage}
            alt="Loading"
            className="loading-image"
            style={{ width: "300px", margin: "auto" }}
          />
        </section>
      ) : (
        <RouterProvider router={router} />
      )}
    </div>
  );
}

export default App;
